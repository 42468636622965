/*------------------------------------------------------- NavBar */

.NavbarItems {
  background: rgb(46, 0, 105);
  /* height: 10vh; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 20px;
  position: relative;
}

.left-container {
   display: flex;
  align-items: center;
  justify-content: flex-start; 
  position: absolute; 
  top: 20px; 
  left: 20px; 
}

.right-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute; 
  top: 80px; 
  right: 20px; 
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .left-container {
    justify-content: center;
    margin-top: 20px;
  }
  .right-container {
    flex-direction: column;
    align-items: center;
    position: static;
    margin-top: 20px;
  }
  .btn {
    margin-bottom: 10px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .left-container {
    margin-left: 20px;
  }
  .right-container {
    margin-right: 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .left-container {
    margin-left: 50px;
  }
  .right-container {
    margin-right: 50px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .left-container {
    margin-left: 100px;
  }
  .right-container {
    margin-right: 100px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .left-container {
    margin-left: 200px;
  }
  .right-container {
    margin-right: 200px;
  }
}
/*------------------------------------------------------- NavBar */

/* Apply Now Btn */
#contactNow {
  color: #198754;
  background-color: #fff;
  border-color: #198754;
}
#contactNow:hover {
  background-color: #198754;
  border-color: #fff;
  color: #fff;
}
/* Apply Now Btn */

/* CardFlip */
.card {
  width: 22rem;
  height: 22rem !important;
  perspective: 31.25rem;
  margin: auto;
  padding: 0.25rem;
  border-color: #fff !important;
}

.content {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: transform 1s;
  transform-style: preserve-3d;
}

.card:hover .content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.front {
  position: absolute;
  height: 100%;
  width: 100%;
  background: black;
  background-image: url("./components/img/homeSplash1.jpeg");
  line-height: 18.75rem;
  color: black;
  /* color: #03446a; */
  text-align: center;
  font-size: 3.75rem;
  border-radius: 0.5rem;
  backface-visibility: hidden;
}

.back {
  height: 100%;
  width: 100%;
  background: #198754;
  color: white;
  transform: rotateY(180deg);
  line-height: 2rem;
  text-align: center;
  font-size: 1rem;
  border-radius: 0.5rem;
  backface-visibility: hidden;
}
/* CardFlip */

/* Parallax Start */
.section {
  min-height: 600px;
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% 50%;
}
.jumbotron {
  margin-bottom: 0;
}
/* Parallax End */

/* Footer */
.footerLinks {
  color: #fff;
  text-decoration: none;
}

.footerLinks:hover {
  color: #000;
}
/* Footer */
